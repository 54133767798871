import { useQuery, UseQueryResult } from 'react-query';
import { apiClient } from 'src/utils/apiClient';

export interface Contact {
  user_id: number;
  user_job_title: string;
  profile_id: number;
  profile_first_name: string;
  profile_last_name: string;
  profile_avatar: string | null;
  user_role: string;
}

interface ContactsResponse {
  code: number;
  error: boolean;
  message: string;
  results: Contact[];
}

async function getContacts(siteCode: string): Promise<Contact[]> {
  const response = await apiClient<ContactsResponse>('/v1/website/organisations/contacts/list', { siteCode });
  return response.results;
}

export function useContactsQuery(
  siteCode: string,
): UseQueryResult<{ id: number; name: string; jobTitle: string; avatar: string }[], Error> {
  return useQuery(
    ['contacts', siteCode],
    async () => {
      const contacts = await getContacts(siteCode);
      return contacts.map(contact => ({
        id: contact.user_id,
        name: `${contact.profile_first_name} ${contact.profile_last_name}`,
        jobTitle: contact.user_job_title,
        avatar: contact.profile_avatar,
      }));
    },
    {
      staleTime: 1000 * 60 * 60 * 24,
    },
  );
}
